import localForage from "localforage";

const fileCache = localForage.createInstance({
  name: "filecache",
});

interface Cell {
  id: string;
  content: string;
  type: "text" | "code";
}

interface SaveState {
  cells: Cell[];
  cumulative: boolean;
}

const initalTextContent = `
# JBook

This is an interactive coding environment. You can write Javascript, see it executed, and write comprehensive documentation using markdown.

- Click any text cell (including this one) to edit it.
- The code in each code is all joined together into one file. If you define a variable cell in #1, you can refer to it in any following cell! _You can turn this off by toggling the \`Enable Inter-Cell Code access\` in the upper right part of the screen._
- You can show any React component, string, number, or anything else by calling the \`show()\` function. This is a function built into this environment. Call show multiple times to show multiple values.
- Re-order or delete cells using the buttons on the top right of the cell.
- Add new cells by hovering on the divider between each cell.

All of your changes are saved to your browser's localStorage.
`;

const initialCodeContent = `import { useState } from 'react';

const Counter = () => {
  const [count, setCount] = useState(0);
  return (
    <div>
      <button onClick={() => setCount(count + 1)}>+</button>
      <button onClick={() => setCount(count - 1)}>-</button>
      <h3>Count: {count}</h3>
    </div>
  );
};

// Display any variable or React Component by calling 'show'
show(<Counter />);`;

const initalState: SaveState = {
  cumulative: true,
  cells: [
    {
      id: Math.random().toString(36).substring(2, 7),
      content: initalTextContent,
      type: "text",
    },
    {
      id: Math.random().toString(36).substring(2, 7),
      content: initialCodeContent,
      type: "code",
    },
  ],
};

export const persist = async (data: SaveState, key: string = "gist") => {
  await fileCache.setItem(key, data);
};

export const load = async (key: string = "gist") => {
  const cached = await fileCache.getItem<SaveState>(key);
  if (cached) return cached;

  return initalState;
};
