import "./cumulative-toggle.css";
import { useActions } from "../hooks/use-actions";

interface CumulativeToggleProps {
  enabled: boolean;
}

const CumulativeToggle: React.FC<CumulativeToggleProps> = ({ enabled }) => {
  const { enableCumulativeCode } = useActions();
  const onChangeHandler = () => {
    enableCumulativeCode(!enabled);
  };

  return (
    <div className="cumulative-toggle">
      <p className="toggle-label">Enable Inter-Cell Code Access</p>
      <label className="switch">
        <input type="checkbox" checked={enabled} onChange={onChangeHandler} />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default CumulativeToggle;
