import { useTypedSelector } from "./use-typed-selector";

export const useCumulativeCode = (cellId: string) => {
  return useTypedSelector((state) => {
    const { cumulative } = state;
    const { data, order } = state.cells;
    const orderedCells = order.map((id) => data[id]);

    const showFunc = `
        import _React from 'react';
        import _ReactDOM from 'react-dom';
    
        var show = (value) => {
          const root = document.querySelector('#root');
          if(typeof value === 'object') {
            if(value.$$typeof && value.props) {
              _ReactDOM.render(value, root);
            } else {
            root.innerHTML = JSON.stringify(value);
            }
          } else {
            root.innerHTML = value;
          }
        }
      `;
    const showFuncNoop = "var show = () => {}";

    const cumulativeCode = [];

    if (!cumulative.enabled) {
      cumulativeCode.push(showFunc);
      const cell = orderedCells.find((cell) => cell.id === cellId);
      cumulativeCode.push(cell!.content);
      return cumulativeCode.join("\n");
    }

    for (let c of orderedCells) {
      if (c.type === "code") {
        if (c.id === cellId) {
          cumulativeCode.push(showFunc);
        } else {
          cumulativeCode.push(showFuncNoop);
        }
        cumulativeCode.push(c.content);
      }
      if (c.id === cellId) {
        break;
      }
    }
    return cumulativeCode.join("\n");
  });
};
