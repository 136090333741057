import produce from "immer";
import { ActionType } from "../action-types";
import { Action } from "../actions";

interface CumulativeCodeState {
  enabled: boolean;
  error: string | null;
}

const initialState: CumulativeCodeState = {
  enabled: true,
  error: null,
};

const reducer = produce(
  (state: CumulativeCodeState = initialState, action: Action) => {
    switch (action.type) {
      case ActionType.ENABLE_CUMULATIVE_CODE:
        state.enabled = action.payload;
        return state;
      default:
        return state;
    }
  },
  initialState
);

export default reducer;
